.search-wrapper {
  width: 100%;
  height: 100%;
  .btn-row {
    display: block;
    padding: 1.25rem 1rem;
    margin-bottom: 5px;
    background: #353535;
  }
  .p-datatable-wrapper {
    overflow: auto;
  }
  .p-datatable table {
    table-layout: auto !important;
  }

  .p-input-icon-left > .p-inputtext {
    padding: 10px 2.5rem 10px 2.5rem;
  }
  .customer-empty-data {
    display: flex;
    justify-content: center;
    padding: 10px 0px;
    font-size: 20px;
    color: var(--text-Non-Active);
  }
  .p-datatable .p-datatable-thead > tr > th {
    border-width: 1px 0px 1px 0px;
  }
}
.center {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.filter-width {
  width: 40%;
}

.input-width {
  width: 100%;
}

.table-Class {
  background: #525252;
  padding: 10px;
}
.dataTable {
  .p-datatable-header {
    padding: 0px !important;
  }
  .p-datatable-thead > tr > th {
    background: #353535 !important;
    color: #ffffff !important;
  }
  .p-datatable-tbody > tr > td {
    background: #4d4b4b !important;
    color: #ffffff !important;
  }
  .p-paginator {
    background: #353535 !important;
  }
}
.p-tabview .p-tabview-panels {
  background: #353535 !important;
}
.add-btn-cus {
  display: flex;
  align-self: center;
  button {
    background: var(--btn-primary);
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
    font-weight: 700;
    border: none;
    color: white;
    &:hover {
      background: #7c12a7c5;
    }
  }
}
.active_customer_badge {
  text-align: right;
  padding: 18px 0px;
  .textHeading {
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    padding: 10px;
    background-color: #111111;
    border-radius: 5px;
    @media screen and (max-width: 500px) {
      font-size: 11px;
    }
  }
}
