.test {
  margin-top: 1.5rem;
  .p-chips-multiple-container {
    padding: 0.75rem 0.75rem !important;
  }
}
.otherleglabel {
  position: absolute;
  left: var(--padding-regular);
  color: var(--label-muted-color);
  margin-left: 2.3rem;
}
.dateTimeWrp{
  margin: 0;
}
