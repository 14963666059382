.meet-container {
  display: flex;
  flex-direction: column;
  width: 7rem;
  background: var(--bg-secondary-header);
  overflow-y: auto;
  box-shadow: 2px 0 4px var(--bg-secondary-header);
  z-index: 3;
  position: relative;
  overflow-x: hidden;
  .side-btn {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: var(--border-primary-thin);

    button {
      padding: 0.5rem;
      font-size: 1.5rem;
      font-weight: 700;
      width: 100%;
      background: transparent;
      border: none;
      transition: 0.3s;
      &:hover {
        background: var(--btn-hover-priamry);
      }
    }
  }
  button.meeting {
    padding: 1rem 2rem;
    border: none;
    color: var(--text-primary);
    font-weight: 600;
    background: transparent;
    transition: 0.3s;
    // border-right: var(--border-primary-thin);
    border-bottom: thin solid rgba(255, 255, 255, 0.103);
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    span > img {
      height: 2.25rem;
      width: 3.3rem;
    }

    span.meeting-name {
      font-size: 0.85rem;
      margin-top: var(--margin-regular);
    }

    &:hover:not(.active-m) {
      background: var(--btn-hover-priamry);
    }
  }

  button.active-m {
    background: var(--btn-primary);
  }
  button.meeting .meetingTooltip {
    visibility: hidden;
    width: 61px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 60px;
    left: 2px;
    // margin-left: -2rem;
    text-transform: capitalize;
    font-size: 8px;
  }
  button.meeting .meetingTooltip::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color:  transparent transparent black transparent;
  }
  button.meeting:hover .meetingTooltip {
    visibility: visible;
  }
}
.add-race-dailog {
  position: fixed;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
  z-index: 99;
}

// .country-flag {
//   height: 50px;
//   width: 50px;
//   border: var(--border-primary-thin);
//   background: var(--bg-contrast-light);
// }

.filter {
  background: var(--bg-secondary-header);
  box-shadow: 0px 2px 4px var(--bg-secondary-header);
  z-index: 2;

  .country-filter {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: var(--border-primary-thin);

    .prvBtn {
      border: none;
      border-right: var(--border-primary-thin);

      background: transparent;
      font-weight: 700;
      padding: var(--padding-regular) var(--padding-medium);
      position: relative;
      display: inline-flex;
      align-items: center;
      &:hover {
        background: var(--btn-hover-priamry);
      }
      &.active,
      &.active:hover {
        background: var(--btn-active);
      }
    }
    .prvBtn .tooltipText {
      visibility: hidden;
      width: 100px;
      background-color: black;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      position: absolute;
      z-index: 1;
      top: 34px;
      left: 30px;
      margin-left: -2rem;
      text-transform: capitalize;
    }
    .prvBtn .tooltipText::after {
      content: "";
      position: absolute;
      bottom: 100%;
      left: 15%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: transparent transparent black transparent;
    }
    .prvBtn:hover .tooltipText {
      visibility: visible;
      background: black;
    }
  }
}

.meet-event-container,
.events {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}

.events {
  transition: width 0.3s ease-in-out;
  &.open {
    width: 40rem;
  }
  &.close {
    .filter {
      display: none;
    }
    .meet-event-container {
      display: none;
    }
    width: 0;
  }
}

.fo-badge {
  margin: 0 0.5rem;
  background: var(--color-ashva-title);
  color: navy;
  display: inline-block;
  font-weight: 700;
  font-size: 0.85rem;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  padding: 0 0.5rem;
}

.open-btn {
  border: none;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  padding: 0.25rem 0.5rem;
  margin-right: 0.35rem;
  background: #2e2e2e;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.race-status {
  padding: 0.1rem;
  display: flex;
  font-weight: 700;
  min-width: 50px;
  margin-right: 0.5rem;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  font-size: 10.2px;
  &.open {
    background: var(--color-blue);
  }

  &.closed {
    background: var(--color-red);
  }

  &.resulted {
    background: var(--color-green);
    color: rgb(34, 34, 34);
  }

  &.non_display {
    background: var(--color-dark);
    color: var(--color-light);
  }
}
.race-no {
  padding: 0.2rem;
  font-weight: bolder;
  max-width: 10px;
  margin-left: 0.5rem;
  border-radius: 5px;
  // background: #ae4afd;
}
.race-card {
  margin: 0.25rem 0.5rem;
  border-radius: 0.35rem;
  background: var(--card-primary);
  transition: 0.3s;
  border: 2px solid rgba(0, 0, 0, 0);
  box-shadow: 0 0 2px #30323ca3, inset 0 0 2px #696969;
  outline: none;
  position: relative;
  display: inline-block;
  &.active-card {
    background: var(--btn-hover-priamry);
  }

  &:hover {
    background: var(--btn-hover-priamry);
    cursor: pointer;
  }

  &:focus {
    box-shadow: 0 0 2px #30323ca3, inset 0 0 2px #333333;
    border: 2px solid #762fb1be;
  }

  .d-row {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;

    .trackName {
      font-size: 1.5rem;
      font-weight: 700;
    }
  }
}
.race-card .countryToolTip {
  visibility: hidden;
  width: 100px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 6%;
  left: 62%;
  margin-left: -1rem;
  text-transform: capitalize;
  font-size: 12px;
}
.race-card .countryToolTip::after {
  content: "";
  position: absolute;
  top: 7px;
  left: 105px;
  margin-left: -5px;
  border-width: 6px;
  border-style: solid;
  border-color: transparent transparent transparent black;
}
.race-card:hover .countryToolTip {
  visibility: visible;
  background-color: black;
}
.calender-container {
  border-radius: 0.5rem;
  overflow: hidden;
  border: var(--border-primary-thin);
  margin: var(--margin-regular);

  .racetype-btns {
    button {
      padding: var(--padding-regular);
      width: 50%;
      background: var(--btn-dark);
      border: none;
      transition: 0.3s;

      &:first-child {
        border-right: var(--border-primary-thin);
      }
      &:hover {
        background: var(--btn-hover-priamry);
      }
      &.active {
        background: var(--btn-active);
      }
    }
  }

  .calender {
    width: 100%;

    .p-inputtext {
      background: var(--bg-input-primary);
      border: none;
      outline: none;
      color: var(--text-primary);
    }
  }
}
