.pl-form-container {
  .col-title,
  .form-title {
    border-bottom: var(--border-primary-thin);
    padding: 0.75rem;
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--text-secondary);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .col-title {
    justify-content: center;
    border: none;
  }
  .form-body {
    padding: 1rem;
  }

  .form-footer {
    border-top: var(--border-primary-thin);
    display: flex;
    padding: 1rem;
    align-items: center;
    justify-content: flex-end;
  }
}

.input-grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap:.5rem,
}
