.pz-prime-table {
  .p-datatable-emptymessage td {
    text-align: center !important;
    font-size: 16px;
  }
}
.wragerSumSubBtn{
  padding: 1rem 2.5rem;
}


.csvButton{
  margin-top: .7rem;
  padding-left: 1rem;
}



