.notification {
  position: fixed;
  z-index: 699;
  display: flex;
  flex-direction: row-reverse;
  overflow-y: auto;
  bottom: 5rem;
  right: 2rem;
  // width: 320px;
  left: 0;
  // box-shadow: 0 0 6px 2px #547725, 0 0 8px 1px #874997;
  // background: gray;
  border-radius: 1.5rem;
  overflow-wrap: break-word;
  .warning {
    background: #ffc800;
    display: inline-block;
    max-width: 30rem;
    flex-shrink: 0;
  }
  .n-head {
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.15rem;
    padding: var(--padding-regular);
    padding-left: 1rem;
    border-bottom: var(--border-primary-thin);

    .warning {
      background: #ffa500;
    }
  }

  .n-body {
    padding: 1rem;
  }

  .n-fotter {
    padding: 0.5rem 1rem;
    font-weight: 600;
    border-top: var(--border-primary-thin);
    .yes {
      background: rgb(23, 161, 23);
      color: white;

      &:hover {
        background: rgb(90, 90, 247);
      }
    }
    .no {
      background: transparent;
      color: #ff4646;
      margin-left: 1rem;

      &:hover {
        background: #ff4646;
        color: white;
      }
    }
  }
}

.n-btn {
  border: none;
  border-radius: 0.5rem;
  background: gray;
  padding: 0.5rem 0.75rem;

  transition: 0.3s;
  &._icon {
    background: transparent;
    &:hover {
      background: var(--btn-hover-priamry);
    }
  }
}
