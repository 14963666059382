.drpdwn {
  .p-dropdown-clear-icon {
    color: #ffffff !important ;
    right: 3.357rem !important;
  }
}
.table-scroll-racelog {
  height: calc(100vh - 38vh);
  overflow: auto;
}
.table-scroll-fieldlogs {
  height: calc(100vh - 37vh);
  overflow: auto;
}
.selection-scroll {
  display: flex;
  align-items: center;
  width: 80%;
}
.subBtn {
  padding: 1rem 2.5rem;
}
.contain {
  display: flex;
  justify-content: space-between;
}

@media screen and (max-width: 1275px) {
  .table-scroll-fieldlogs {
    height: calc(100vh - 50vh);
  }
  .table-scroll-racelog {
    height: calc(100vh - 50vh);
  }
  .selection-scroll {
    width: 100%;
  }
}
@media screen and (max-width: 1523px) {
  .table-scroll-fieldlogs {
    height: calc(100vh - 61vh);
  }
  .table-scroll-racelog {
    height: calc(100vh - 54vh);
  }
}
@media screen and (max-width: 1744px) {
  .contain {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
}