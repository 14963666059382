.sidebar_wrapper {
  width: 100%;

  .empty_data {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    span {
      font-size: 35px;
    }
  }
  .p-sidebar-content {
    width: 100%;
    height: 100%;
  }
  .search-input {
    color: #fff;
    width: 100%;
  }
  .p-datatable-header {
    background: transparent !important;
    border: none !important;
    position: sticky;
    z-index: 32;
    top: 0;
    width: 30%;
    display: flex;
    justify-content: flex-start;
    float: right;
  }
  .sidebar_body {
    width: 100%;
    height: 100%;
    margin-top: 20px;
    max-height: 90vh;
    .p-datatable-wrapper {
      background: #323232;
    }
    .title {
      font-size: 20px;
      margin-bottom: 10px;
      background: #353535;
      padding: 1rem 1rem;
    }
    .p-datatable-thead {
      position: sticky;
      top: 0;
      background: #323232;
      border: 1px solid #5b5b5b;
      tr > th {
        border: 2px solid #5b5b5b;
        border-width: 1px 0 2px 0px;
      }
    }
    .table_body_wrapper {
      max-height: 80vh;
      overflow-y: scroll;
      .p-datatable .p-component {
        margin-bottom: 40px !important;
      }
    }
    .p-datatable .p-datatable-thead > tr > th .p-sortable-column {
      background: #353535;
      border: 1px solid #5b5b5b;
    }
  }

  .p-datatable .p-datatable-thead > tr > th,
  .p-datatable .p-datatable-tbody > tr,
  .p-datatable .p-datatable-tbody > tr td {
    background: transparent;
    color: #fff;
    border-width: 0px;
    border: 1px solid #5b5b5b;
  }
  .p-datatable
    .p-sortable-column:not(.p-highlight):not(.p-sortable-disabled):hover {
    background: none !important;
    color: #fff;
  }
  .p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
    background: none !important;
    color: #fff !important;
  }
  .p-sidebar {
    width: 40%;
    background: #3f3f3f;
    color: #fff;
    z-index: 100;
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    span {
      width: 50%;
    }
  }
}
