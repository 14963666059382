.add-form-content {
  display: flex;
}
.ctry-container {
  background: var(--bg-secondary-header);
  height: inherit;
  // overflow-y: auto;
  padding: 0.5rem;
  display: inline-block;
  .search-box {
    position: relative;
    .search-icon {
      position: absolute;
      right: 0;
      bottom: 0;
      background: rgb(63, 63, 63);
      border-radius: 20rem;
      height: 20px;
      width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .ToatRuleCancleBtn{
    border: 1px solid ;
  }

  input.search-ctry {
    background: var(--bg-input-primary);
    color: var(--text-primary);
    text-align: center;
  }
  input.search-ctry,
  button.ctry-btn {
    display: block;

    width: 50px;
    height: 50px;
    font-weight: 600;
    font-size: 1.2rem;
    border-radius: 10rem;
    outline: none;
    margin: 10px 0;
    border: 2px solid var(--bg-secondary-header);
    transition: 0.3s;
  }

  button.ctry-btn {
    background: #383c40;
    &.active {
      background: var(--btn-active-dark);
    }
  }
  button.ctry-btn.active,
  button.ctry-btn:focus,
  button.ctry-btn:hover,
  input.search-ctry:focus {
    border: 2px solid var(--btn-hover-primary-dark);
  }
}

.track-filter {
  width: 20rem;
  background: var(--bg-secondary-header);
  // overflow: auto;
  position: relative;
  margin: 1rem;
  border-radius: var(--radius-50);

  .filter-header {
    background: var(--bg-primary-header);
    padding: var(--padding-medium);
    font-weight: 700;
    position: sticky;
    box-shadow: 0 0 8px #333333;
    top: 0;
    border-radius: var(--radius-50);
    display: flex;
    justify-content: space-between;
  }

  button.track-btn {
    border: none;
    // border-bottom: thin solid gray;
    padding: 10px;
    border-radius: var(--radius-50 /*  */);
    background: transparent;
    width: 100%;
    font-weight: 700;
    font-size: 1.2rem;
    display: flex;
    margin: 0.5rem 0;
    justify-content: space-between;
    transition: 0.3s;
    &:hover {
      background: var(--btn-hover-priamry);
    }

    &.active {
      background: var(--btn-active-dark);
    }
  }
}

.add-pool-container {
  .container-header {
    background: var(--card-primary);
    border-radius: 0.5rem;
    font-size: 2rem;
    font-weight: 700;
    padding: var(--padding-medium);
    width: 100%;
  }
}

.selected-track-container {
  padding: var(--padding-medium);
  background: var(--card-header-primary);
}

.tracks-table {
  background: rgba(53, 53, 53, 0.219);
  max-height: 55vh;
  overflow-y: auto;
  table {
    width: 100%;
    tr > td {
      padding: var(--padding-medium);
    }
  }

  tr > td:first-child,
  tr > td:last-child {
    width: 5%;
  }
}

.detail-label {
  color: rgb(151, 151, 151);
  font-weight: 600;
  font-size: 0.9rem;
  font-style: italic;
}

.rule-card-grid {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  .pz-card {
    width: 18%;
    height: 32rem;
  }
  .detail-container {
    padding: var(--padding-regular);
    margin-bottom: 1rem;

    .detail {
      font-weight: 600;
    }
  }
}

.panel-container {
  display: flex;
  width: 100%;
  height: 12rem;
  .pz-panel {
    width: 100%;
    margin: 0 10px;
    background: var(--bg-secondary-header);
    border-radius: 0.5rem;
    padding: var(--padding-regular);
    overflow-y: auto;
    height: inherit;
  }
}
.listTracks{
  
  .p-listbox-header {    
    background: #707070 !important;   
 }
 .p-listbox-list .p-listbox-item:hover {
   background: #883cae !important;
 }

.p-listbox-list {
  margin: 0 !important;
  padding: 0 !important;  
}

.p-listbox-list-wrapper {
  background: #484848 !important;
}
 .trackbtn{
  border: none;
  background: transparent;
  width: 100%;
  font-weight: 700;
  font-size: 1.2rem;
  display: flex;
  margin: 0.2rem 0;
  justify-content: space-between;
  transition: 0.3s;
  color: aliceblue;
 }
}