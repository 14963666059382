.poolNamebtnCont{
  margin: 1px;
  padding: 1px;
  .btn{
  background-color: #525252;
  color: white;
  width: 100%;
  padding: 0.1rem;
  display: flex;
  font-weight: 700;
  min-width: 50px;
  margin-right: 0.5rem;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  }
}
.racestatus{
  padding: 0.1rem;
  display: flex;
  font-weight: 700;
  min-width: 50px;
  margin-right: 0.5rem;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}