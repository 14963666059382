.pz-overlaypanel {
  background: #303238 !important;
  box-shadow: 1px 1px 10px rgba(43, 43, 43, 0.685) !important;
  padding: 0 !important;

  &::before {
    display: none;
  }
}

.table-card {
  border-radius: 1rem;
  border-top: var(--border-primary-thin);
  border-bottom: var(--border-primary-thin);
  margin: var(--margin-medium);
  background: var(--card-primary);
  overflow: auto;

  .table-card-header {
    background: var(--table-header-dark);
    padding: var(--padding-medium);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.scratched {
  color: rgb(219, 44, 44);
  text-decoration: line-through;
}

.pz-table {
  width: 100%;
  border-radius: 0.5rem;
  overflow: hidden;

  thead > tr > th {
    padding: 0.5rem;
    border: var(--border-primary-thin);
    background: var(--table-header-dark);
    &:not(:last-child) {
      border-right: var(--border-primary-thin);
    }
  }
  tbody > tr > td {
    border: var(--border-primary-thin);
    padding: var(--padding-regular);
  }
}
.action-btn {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: none;
  background: transparent;
  transition: 0.3s;

  &:hover {
    background: var(--btn-hover-priamry);
  }
}

.apply-all-btn {
  border: none;
  background: #92929225;
  border-radius: var(--radius-35);
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
  width: 100%;
  align-items: center;
  transition: 0.3s;
  &:hover {
    background: gray;
  }
  &:disabled {
    color: gray;
    &:hover {
      background: #92929225;
    }
  }
}
.row-t {
  transition: 0.3s ease;
  position: relative;
}
.row-t:hover {
  background: rgba(73, 73, 73, 0.301);
}

.cell-input {
  background: #484848;
  width: 100%;
  border-radius: (--radius-35);
  outline: none;

  &:focus {
    border: var(--border-focus-thin);
  }
}

.pz-overlaypanel {
  background: #303238 !important;
  box-shadow: 1px 1px 10px rgba(43, 43, 43, 0.685) !important;
  padding: 0 !important;

  &::before {
    display: none;
  }
}

.panel-input {
  outline: none;
  background: #30323ab6;
  border: 1px solid #5a5a5a;
  border-radius: 0.35rem;
  width: 100%;
  padding: 10px;
  transition: 0.3s;
  &:hover,
  &:focus {
    border: 1px solid #883cae;
  }
}

.update-btn {
  border: none;
  border-radius: 0.35rem;
  margin: 1rem 0rem;
  padding: 0.5rem 1.5rem;
  background: #883cae;
  transition: 0.3s;

  &.danger {
    background: rgb(247, 105, 105);
  }

  &:hover {
    background: #883caea4;
  }
}

.selId-abs {
  position: absolute;
  top: 0rem;
  right: 0rem;
  font-size: 5rem;
  color: rgba(128, 128, 128, 0.432);
  font-weight: 800;
  display: flex;
  flex-direction: column;
}

.payoff {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.1rem 0.2 rem;
  background-color: rgb(36, 36, 36);
  margin-right: 1rem;
  font-size: 1.2rem;
  font-weight: 600;
  min-width: 7rem;
  border-radius: 0.5rem 0.5rem 0rem 0rem;
}

.null-cell {
  font-size: 2.5rem;
  color: var(--label-muted-color);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45vh;
}

.panel-table-container {
  border: var(--border-primary-thin);
  border-radius: 0.5rem;
  // overflow: hidden;
  height: 200px;
  overflow-y: auto;
  .panel-table {
    width: 100%;
    & > thead > tr > th {
      background: #23252c;
    }

    & > thead > tr > th,
    & > tbody > tr > td {
      padding: 0.5rem;
      border: thin solid #23252c;
    }
  }
}

.sel-id-btn {
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 35px !important;
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  font-weight: 600;
  font-style: italic;
  color: #d9beef;
  .ic {
    transition: 0.3s;
    color: #979797;
  }
  &:hover {
    background: #6b2ea570;
    cursor: pointer;
  }
}

.po-col {
  padding-left: 1rem;
  margin-left: 1rem;
  border-left: thin solid rgb(70, 69, 69);
  color: white;
  max-height: 17rem;
  min-width: 17rem;
  overflow: auto;
  .t-card {
    border-radius: 0.5rem;
    overflow: hidden;
  }
}

.btn-req {
  padding: 0.2rem;
  display: flex;
  font-weight: 700;
  min-width: 50px;
  align-items: center;
  justify-content: center;
  background: var(--btn-primary);
  border-color: var(--btn-primary);
  border-radius: 0.5rem;
}

.btn-req-auto {
  padding: 0.1rem;
  display: flex;
  font-weight: 700;
  min-width: 50px;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background: #7a47ab;
  border-color: #7a47ab;
}

.winners {
  color: gold;
}

.winnerRow {
  background-color: #1514155c;
}
