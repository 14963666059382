@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Staatliches&display=swap");

@font-face {
  font-family: "CodaCaption";
  src: local("CodaCaption"),
    url("assets/fonts/CodaCaption-ExtraBold.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  font-size: 12px;
  scroll-behavior: smooth;
  background: #39323f;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
}

.font-1 {
  font-family: "Staatliches", "Bebas Neue", "Lucida Sans", "Lucida Sans Regular",
    "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  letter-spacing: 2px;
}

a:hover {
  text-decoration: none !important;
}

.c-success {
  color: #58ff05 !important;
}

.c-danger {
  color: rgb(253, 76, 76);
}

.pz-dialog {
  .p-dialog-header {
    background: #3d3d3d !important;
    color: white !important;
  }
  .p-dialog-content {
    background: #545454 !important;
    color: white !important;
  }
  .p-dialog-footer {
    background: #3d3d3d !important;
    color: white !important;
  }
}
