.form-body {
    padding: 1rem;
    .col-title,
    .form-title {
        border-bottom: var(--border-primary-thin);
        padding: 0.75rem;
        font-size: 1.3rem;
        font-weight: 600;
        color: var(--text-secondary);
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .col-title {
        justify-content: center;
        border: none;
    }
}
.labelcss {
    margin: 0;
    position: absolute;
    bottom: 100%;
    left: var(--padding-regular);
    color: var(--label-muted-color);
    transition: 0.3s;
}
.inputMask-container {
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0;
    padding: 0 0.4rem;
    width: 100%;

    .inputMask {
        padding: 10px;
        color: white;
        background: #464646;
        padding: 1rem 0.75rem;
        border: 1px solid #565656;
        transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s;
        border-radius: 4px;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
    }
    .label-Mask {
        display: inline-block;
        margin-bottom: 0.5rem;
        color: #bbbbbb;
    }
}
.pooltype{
    padding: var(--padding-regular);
    border: none;
    display: flex;
    .selectedPoolCategory{
        padding: 0.1rem;        
        font-weight: 700;
        min-width: 60px;
        margin-right: 0.5rem;
        background: #464646;
        border-radius: 0.2rem;
        border: 1px solid #6a6b6c;
    }
    .active-selectedPoolCategory{
        background: #883cae;
        padding: 0.1rem;        
        font-weight: 700;
        min-width: 60px;
        margin-right: 0.5rem;
        border-radius: 0.2rem;
        border: 1px solid var(--border-primary-thin);
    }
}
.dateTimeWrp{
    margin: 0;
}