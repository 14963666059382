.add-customer-wrapper {
  .sidebar {
    width: 40%;
    background: #323232;
    @media (max-width: 900px) {
      width: 100%;
    }
  }
  .header {
    padding: 0px 8px 8px 0px;
    border-bottom: 1px solid #fff;
    margin: 0px 8px 8px 8px;
    .title {
      font-size: 20px;
      color: #fff;
      text-transform: capitalize;
    }
  }
  .customer_row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    flex-wrap: wrap;
    .action_btn {
      color: #fff;
    }
  }
  .dropdown_row {
    min-width: 200px;
    color: #fff;
    @media screen and (max-width: 1580px) {
      min-width: 150px;
    }
  }
  .button_fun {
    display: flex;
    align-self: center;
    border: none;
    background: #883cae;
    margin: 8px 10px !important;
    color: #fff;
  }
  .dubble-hash-fun-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
.padwrp{
  padding: 0;
}
.inputbackWrp{
  background-color: #525252 !important;
}