.nextRacesWrapper {
  // display: block;
  background-color: rgb(19, 19, 19);
  margin: 0 0.5rem;

  flex-grow: 1;
  border-radius: 0.5rem;
  overflow: hidden;
  .nextRaces {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    .races {
      flex-grow: 1;
      display: flex;
      overflow: auto;
      scroll-behavior: smooth;
      flex-wrap: nowrap;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}
.raceCard {
  padding: 0.25rem 0.5rem;
  margin: 0.25rem;
  border-radius: 0.25rem;
  background-color: #313131;
  min-width: 220px;
  transition: 0.3s;
  box-shadow: 0 0 3px #322c42;
  transition: 0.3s;
  &:hover {
    cursor: pointer;
    background: var(--btn-active);
  }
  .bottomRow {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.raceNo {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--color-ashva-title);
}

.arrowButtons {
  button {
    display: block;
    background: transparent;
    border: none;
    height: 100%;
    font-size: 2rem;
    padding: 0 0.5rem;
    font-weight: 600;
    transition: 0.3s;
    &:hover {
      background: var(--btn-active-dark);
    }
  }
}

.op-btn-2 {
  background: transparent;
  border: none;
  border-radius: 0.25rem;
  padding: 0 0.25rem;
}
