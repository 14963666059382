.chat_wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 10px;
}
.pie_chart_block{
    width: 100%;
    max-width: 350px;
}
.province_data_title{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    font-size: 18px;
    margin-top: 10px;
    text-transform: capitalize;
}