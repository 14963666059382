.footer-container {
  position: relative;
  z-index: 5;
  width: 100%;
}

.footer-content {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  background: var(--bg-primary-header);
  color: var(--text-primary);
  // padding: 10px;
  height: var(--height-footer);
  box-shadow: -2px 0px 3px rgb(134, 134, 134);
}

.copyrightWrapper {
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  a {
    color: #53baff;
    &:hover {
      color: #0077ff;
    }
  }
}
