.left_section {
  width: 70%;
  // height: 100%;
  margin-right: 5px;
  padding-bottom: 6rem;
  overflow: auto;
  .p-datatable table {
    table-layout: auto !important;
  }

  .sub_header {
    display: flex;
    justify-content: space-between;
    background: none;
    padding: 10px;
    margin: 0px 0px 5px 0px;
    background: #2b2a2a;
    .flex_row {
      display: flex;
      flex-wrap: wrap;
      @media screen and (max-width: 1155px) {
        justify-content: space-between;
      }
    }
  }
  .dropdown_row {
    min-width: 250px;
    text-transform: capitalize;
    .input-container {
      margin: 0;
    }
    @media screen and (max-width: 1580px) {
      min-width: 150px;
    }
    @media screen and (max-width: 1155px) {
      margin-bottom: 10px;
    }
  }
  .table-body {
    width: 100%;
    // height: 100%;
    // max-height: calc(80vh - 5px);
    background: #2b2a2a;
    overflow-x: auto;
    overflow-y: hidden;
    border-radius: 0.5rem;

    .p-datatable {
      width: 100% !important;
      overflow-x: auto;
      overflow-y: hidden;
      max-height: 50rem;
    }
    .dataTable .p-datatable-tbody > tr > td {
      background: #2b2a2a !important;
    }
    .p-datatable .p-datatable-thead > tr > th {
      border-width: 1px 0 1px 0;
    }
    .p-datatable .p-datatable-thead > tr > th,
    .p-datatable .p-datatable-tbody > tr > td {
      border: 1px solid #6e7072;
    }
    .p-datatable table {
      border-width: 1px;
      border: 1px solid #6e7072;
    }
    .p-paginator {
      border: none;
    }
  }
  @media screen and (max-width: 1380px) {
    width: 60%;
  }
}
.right_section {
  width: 30%;
  height: 100%;
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 5px;
    background: #2b2a2a;
    height: 64px;
    padding: 10px;
    h2 {
      @media screen and (max-width: 1380px) {
        font-size: 16px;
      }
    }
  }
  .right_section_body {
    width: 100%;
    height: 100%;
    max-height: calc(80vh - 5px);
    background: #2b2a2a;
    overflow: auto;
  }
  @media screen and (max-width: 1380px) {
    width: 40%;
  }
}
.action_btn {
  font-weight: 700;
  margin: 0px 10px;
  padding: 5px 10px;
  border-radius: 4px;
}
.contain_filter {
  background: #252525;
  padding: 8px;
}

.title {
  padding: 0px 0.5rem;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  color: #bbbbbb;
}

.empty_data {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - 64px);
  font-size: 20px;
  color: var(--text-Non-Active);
}
.add-edit-btns {
  font-size: 15px;
  cursor: pointer;
  margin: 0px 10px;
}
.add_btn {
  padding: 10px;
  display: flex;
  align-items: center;
  background: #883cae;
  border: none;
  font-weight: 700;
  border-radius: 4px;
  color: #fff;
  @media screen and (max-width: 1350px) {
    .display_none {
      display: none;
    }
  }

  .icon {
    margin-right: 5px;
  }
}
.p-dropdown-panel{
.p-dropdown-items{ 
.p-dropdown-item{
  text-transform: capitalize;
}
}
}