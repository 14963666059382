.tab-view {
    .p-tabview-panels {
        background: var(--bg-primary) !important;
        padding: 1.25rem;
    }
    .p-tabview-nav {
        padding: 10px !important;
    }
    .p-tabview-nav li .p-tabview-nav-link {
        border-color: var(--bg-primary) var(--bg-primary) var(--bg-primary) !important;
        background: var(--bg-primary) !important;
        color: var(--text-primary) !important;
        padding: 0.75rem 1rem !important;
    }
    .p-tabview-nav li.p-highlight .p-tabview-nav-link {
        background: var(--card-primary) !important;
        border-color: var(--text-primary) var(--text-primary) var(--text-primary) !important;
        color: var(--text-primary) !important;
    }
}
.balance-container {
    color: var(--text-primary);
    text-align: center;
    padding: 10px;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background: #3f3f3f !important;
    border-color: #3f3f3f !important;
    color: var(--text-primary) !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    text-decoration: none;
}
.p-accordion .p-accordion-header .p-accordion-header-link {
    padding: 1rem 1.25rem !important;
    border: 1px solid #3f3f3f !important;
    color: #e8f0fe !important;
    background: #3f3f3f !important;
    font-weight: 600 !important;
    text-decoration: none;
}
.p-accordion .p-accordion-content {
    border: 1px solid #3f3f3f !important;
    background: #525252 !important;
    color: #212529 !important;
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
}
.card-label {
    font-size: 1.25rem;
    font-weight: 700;
    font-style: italic;
    color: #999999;
}
