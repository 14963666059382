.input-container {
  display: flex;
  flex-direction: column;
  margin: 1.3rem 0;
  // padding: 0 0.5rem;
  width: 100%;

  .input {
    position: relative;

    label.input-label {
      margin: 0;
      position: absolute;
      bottom: 100%;
      left: var(--padding-regular);
      color: var(--label-muted-color);
      transition: 0.3s;
    }

    input {
      outline: none;
      padding: var(--padding-medium);
      border-radius: var(--radius-35);
      width: 100%;
      background: var(--bg-input-primary);
      border: var(--border-primary-thin);
      transition: 0.3s;
    }

    input:not(:disabled):focus,
    input:not(:disabled):hover {
      border: var(--border-focus-thin) !important;
    }

    input:focus + label.input-label,
    input:hover + label.input-label {
      color: var(--color-primary-light);
    }

    input:disabled {
      color: var(--label-muted-color);
    }

    input:disabled + label.input-label {
      color: var(--disabled-status);
    }
  }
}

.p-autocomplete .p-autocomplete-multiple-container:not(.p-disabled).p-focus {
  box-shadow: none !important;
  border: var(--border-focus-thin) !important;
}

.search-container {
  position: relative;

  label {
    margin: 0;
    padding-left: var(--padding-regular);
    color: var(--label-muted-color);
  }

  .searchable-input {
    .p-autocomplete-multiple-container {
      width: 100%;
      background: var(--bg-input-primary);
      border: var(--border-primary-thin);
      border-radius: var(--radius-35);
      padding: 0.65rem;

      .p-autocomplete-input-token input {
        color: var(--text-primary);
      }

      .p-autocomplete-token {
        margin-bottom: 0.5rem;
      }
    }

    .p-autocomplete-multiple-container:not(.p-disabled):hover {
      border: var(--border-focus-thin);
      box-shadow: none;
    }
  }
}

.checkbox-container {
  display: flex;
  align-items: center;
  padding: 0 0.5rem;
  margin: 1rem 0;
  &:hover {
    cursor: pointer;
    .label {
      color: var(--text-primary);
    }
    .checkbox {
      border: var(--border-focus-thin);
    }
  }

  .label {
    font-size: 1rem;
    color: var(--text-note);
    transition: 0.3s;
  }
  .checkbox {
    background: transparent;
    margin: 0;
    height: 2rem;
    width: 2rem;
    font-size: 1.5rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: var(--border-primary-thin);
    border-radius: var(--radius-35);
    outline: none;

    &:focus {
      border: var(--border-focus-thin);
    }

    &.selected {
      background: var(--btn-active-dark);
    }
  }
}

.prime-datepicker {
  width: 100%;
  input {
    color: white;

    &:focus {
      box-shadow: none !important;
    }
  }
}
//*change
.prime-drop {
  width: 100%;
  margin-right: .5rem;
  background: var(--bg-primary) !important;
  border: var(--border-primary-thin) !important;
  padding: var(--padding-regular) !important;
  .p-dropdown-label,
  .p-dropdown-trigger {
    color: var(--text-primary) !important;
  }

  .p-dropdown-label.p-placeholder {
    color: #a5a5a573 !important;
  }
}
.dropDownWrp {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
