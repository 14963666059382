.track-grid-pools {
    max-height: 75vh;
    overflow: auto;
    button {
        background: var(--card-primary);
        margin: 0.25rem 0;
        padding: 0.5rem 1rem;
        border-radius: var(--radius-50);
        box-shadow: 0 0 5px #24242479, 0 0 5px #545454c7;
        border: var(--border-primary-thin);
        display: flex;
        width: 100%;
        justify-content: space-between;
        font-weight: 600;
        text-align: left;
        &:hover {
            background: var(--btn-hover-priamry);
        }
        &.active {
            background: var(--btn-active);
        }
    }
}
.inputDateContain .input {
    position: relative;
    width: 20%;
}

.ctry-container-pool-add {
    background: var(--bg-secondary-header);
    height: inherit;
    overflow-y: auto;
    padding: 0.5rem;
    display: inline-block;
    width: 6rem;
    .search-box {
        position: relative;
        .search-icon {
            position: absolute;
            right: 0;
            bottom: 0;
            background: rgb(63, 63, 63);
            border-radius: 20rem;
            height: 20px;
            width: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    input.search-ctry {
        background: var(--bg-input-primary);
        color: var(--text-primary);
        text-align: center;
    }
    input.search-ctry,
    button.ctry-btn {
        display: block;

        width: 50px;
        height: 50px;
        font-weight: 600;
        font-size: 1.2rem;
        border-radius: 10rem;
        outline: none;
        margin: 10px 0;
        border: 2px solid var(--bg-secondary-header);
        transition: 0.3s;
    }

    button.ctry-btn {
        background: #383c40;
        &.active {
            background: var(--btn-active-dark);
        }
    }
    button.ctry-btn.active,
    button.ctry-btn:focus,
    button.ctry-btn:hover,
    input.search-ctry:focus {
        border: 2px solid var(--btn-hover-primary-dark);
    }
}
.race-card-poolAdd {
    margin: 0.25rem 0.5rem;
    border-radius: 0.35rem;
    background: linear-gradient(162deg, #201f1f, #1c1c1c42);
    transition: 0.3s;
    border: 2px solid rgba(0, 0, 0, 0);
    box-shadow: 0 0 2px #30323ca3, inset 0 0 2px #696969;
    min-width: 200px;
    .d-row {
        display: flex;
        justify-content: space-between;
        padding: 0.5rem;

        .trackName {
            font-size: 1.2rem;
            font-weight: 700;
        }
    }
}
.add-pool-card {
    background: #323232;
    overflow-x: auto;
    width: 100%;

    .pool-card-header {
        padding: var(--padding-medium);
        background: var(--card-header-primary);
        font-weight: 900;
        font-size: 15px;
    }
}
.race-card-container {
    display: flex;
    overflow-x: auto;
}
.track-filter-pools{
    width: 20rem;
    background: var(--bg-secondary-header);
    overflow: auto;
    position: relative;
    margin: 1rem;
    border-radius: var(--radius-50);
  
    .filter-header {
      background: var(--bg-primary-header);
      padding: var(--padding-medium);
      font-weight: 700;
      position: sticky;
      box-shadow: 0 0 8px #333333;
      top: 0;
      border-radius: var(--radius-50);
    }
  
    button.track-btn {
      border: none;
      // border-bottom: thin solid gray;
      padding: 10px;
      border-radius: var(--radius-50 /*  */);
      background: transparent;
      width: 100%;
      font-weight: 700;
      font-size: 1.2rem;
      display: flex;
      margin: 0.5rem 0;
      justify-content: space-between;
      transition: 0.3s;
      &:hover {
        background: var(--btn-hover-priamry);
      }
  
      &.active {
        background: var(--btn-active-dark);
      }
    }
}