.loader,
.loader-inline {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #4f326642;
  z-index: 655;
}

.loader-inline {
  position: absolute;
  z-index: 49;
}
