.post {
  font-weight: 600;
  font-size: 1.2rem;
}

.post-green {
  color: #d7ff05;
}
.post-red {
  color: #ff5050;
}
.post-blue {
  color: #05b0ff;
}
