.pref-dialog-class {
  .p-dialog-header {
    background: #3b3b3b !important;
    color: white !important;
    .p-dialog-header-icon {
      color: var(--color-light) !important;
    }
  }
  .p-dialog-content {
    background: #c4c4c4 !important;
    padding: 1rem !important;
  }
  .p-dialog-footer {
    background: #3b3b3b !important;
    padding: 0.5rem !important;
  }
}
.p-dialog-mask.p-component-overlay {
  background-color: rgba(94, 94, 94, 0.01) !important;
}
.btn-style {
  background: var(--btn-primary);
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  font-weight: 700;
  white-space: nowrap;
  border: none;
  box-shadow: 0px 2px 10px 0 #0000332b;
}
.headerClass {
  .p-accordion-header-text {
    width: 100%;
  }
}
.search-input {
  position: relative;
  border: var(--border-primary-thin);
  border-radius: 0.5rem;
}
// .search-input:hover {
//   border: var(--border-focus-thin) !important;
// }
.inputWrp {
  border: none !important;
  width: 80% !important;
  margin-right: 1rem;
  padding: 0.5rem !important;
  background: transparent !important;
}
