.pools-wrapper {
  position: fixed;
  top: var(--height-header);
  bottom: 0;
  right: 0;
  z-index: 51;
  background: var(--bg-primary-header);
  box-shadow: -3px 0px 5px 0px #00000069;
  display: flex;
  .pool-form {
    width: 88.5rem;
    overflow-y: auto;
  }
  .pool-cards {
    width: 38rem;
    box-shadow: -3px 0px 5px 0px #00000069;
    overflow-y: auto;
    padding-bottom: 9rem;
    .pc-header {
      padding: 0.5rem;
      border-bottom: var(--border-primary-thin);
      font-size: 1.32rem;
      font-weight: 700;
      text-align: center;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}

.pool-card {
  display: flex;
  border-radius: 0.35rem;
  margin: var(--margin-regular);
  overflow: hidden;
  border: var(--border-primary-thin);
  box-shadow: inset 1px 2px 20px 0px #5d5d5d70;
  .pool-info,
  .pool-payoff {
    width: 50%;
    padding: var(--padding-regular);
    background: #161617;
  }

  .pool-info {
    background: rgb(31, 31, 31);
  }
}

.close-abs-btn {
  position: absolute;
  top: 0;
  right: 100%;
  border-radius: 25rem;
  height: 3rem;
  width: 3rem;
  font-weight: 600;
  font-size: 1.3rem;
  border: none;
  transition: 0.3s;
  margin: 0.3rem 0.5rem;
  background: transparent;
  &:hover {
    background: var(--color-red);
  }
  // background: red !important;
}

.display-btns {
  border: var(--border-primary-thin) !important;
  border-radius: 0.5rem;
  font-size: 14px !important;
  // border-bottom-left-radius: 0.5rem;
  // border-bottom-right-radius: 0.5rem;

  background: linear-gradient(45deg, #1f2122, #191b24) !important;
  margin: 0.5rem 0.5rem 0rem 0.5rem;
  padding: 0.35rem 1rem !important;

  &:hover {
    background: rgba(29, 30, 56, 0.397) !important;
  }

  &.active {
    background: var(--btn-active-dark) !important;
  }
}

.RESULTED {
  color: var(--color-green);
}
.OPEN {
  color: var(--color-blue);
}

.CLOSED {
  color: var(--color-red);
}

.req-timer-container {
  padding: 0.5rem 1rem;
  font-style: italic;
  font-size: 1.25rem;
  .timer {
    font-size: 2rem;
    font-weight: 600;
    color: #26afff;
  }
}

.btnreq {
  padding: 0.1rem;
  display: flex;
  font-weight: 700;
  // min-width: 50px;
  // margin-right: 0.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  background: var(--btn-primary);
  border-color: var(--btn-primary);
}
